/* Footer */
.footer {
  padding: 20px 0;
  border-top: 1px solid #373737;
  background-color: #191919;
}

.footer p,
.footer a {
  color: #fff;
  display: flex;
}

.footer p {
  width: 40%;
  display: flex;
  align-items: center;
  color: #fff;
}

.befFooter p {
  color: #191919;
  font-size: 16px;
  line-height: 1.5;
}

.footer p:last-child {
  justify-content: right;
}

.footer a {
  text-decoration: underline;
  margin-left: 5px;
}

.footer p img {
  margin-right: 20px;
  width: 60px;
}

.befFooter h2 {
  padding-bottom: 20px;
  color: #191919;
  font-weight: 700;
}

.footer img {
  width: 40px;
  margin-right: 20px;
  height: auto;
  filter: invert(1) brightness(100);
}

.logosFooter {
  width: 31%;
  justify-content: space-around;
}

.logosFooter img {
  width: 130px;
  margin-bottom: 60px;
  object-fit: contain;
}

.tpaMod h4 {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
}

.col3 {
  margin-top: 30px;
  display: flex;
  width: 80%;
  justify-content: space-around;
}

.col3 > div {
  display: flex;
  align-items: center;
}

.col3 img {
  transform: rotate(-90deg);
  width: 30px;
  margin-right: 17px;
  margin-top: 2px;
}

/* Media */

@media (max-width: 850px) {
  .logosFooter {
    width: 70%;
  }

  .tpaMod img {
    height: 60px;
    max-width: 100px;
  }
  .tpaMod h4 {
    font-size: 16px;
  }
  .col3 {
    width: 100%;
  }
  .col3 > div {
    flex-direction: column;
  }
  .col3 img {
    transform: rotate(0deg);
    margin: 0;
    height: 30px;
  }
  .col3 p {
    font-size: 13px;
    text-align: center;
  }
}

/*  /Footer */
