.fullH {
  margin-top: 20px;
  margin-bottom: 60px;
}
.fullH h1 {
  color: #fafafa;
}

.fullH h4 {
  color: #00000081;
  font-weight: 500;
  margin-bottom: 0;
}
.modelo {
  margin-left: 90px;
}
.fullH h3 {
  color: #fff;
  font-weight: 400;
}

.stroke {
  -webkit-text-stroke: 1px #fafafa;
  color: transparent;
  font-weight: 700;
}

.textCol {
  width: 50%;
  min-width: 500px;
}
.modelo {
  width: 50%;
}

.redBox {
  height: 100%;
  width: 100%;
  z-index: 0;
  left: 0;
  bottom: 0;
  background: #eb0a1e;
  padding: 40px 60px 60px;
  border-radius: 5px;
  position: relative;
}

.fullw {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  height: 100px !important;
}
.modelo {
  pointer-events: none;
}

.fullH img {
  width: 100%;
  max-width: 1300px;
  transform: scale(1.9);
  transform-origin: left;
  position: relative;
  top: 30%;
  left: -20%;
}

/* Media */

@media (max-width: 1200px) {
  .redBox {
    padding: 30px 30px 50px;
    flex-direction: column;
    margin-bottom: 100px;
  }
  .textCol {
    width: 100%;
    min-width: auto;
  }
  .modelo {
    width: 100%;
  }
  .fullH img {
    transform: scale(1.5);
    max-width: 450px;
    top: auto;
    left: auto;
  }
  .modelo {
    position: absolute;
    bottom: -30%;
    width: 100vw;
    right: -40px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 850px) {
  .fullH img {
    max-width: 330px;
  }
  .modelo {
    bottom: -25%;
  }
}
