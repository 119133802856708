.header {
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 9999;
  background: #ffffff;
  padding: 10px;
  border-bottom: solid 1px rgba(55, 55, 55, 0.1);
}

.header > div {
  height: 100%;
  display: flex;
  align-items: center;
}

.header img {
  height: 100%;
  max-height: 50px;
  max-width: 130px;
  object-fit: contain;
}

.logo {
  justify-content: space-between;
}

.logoCons {
  width: auto;
  max-width: 100px !important;
}
