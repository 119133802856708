/* Models */
.descuento {
  position: absolute !important;
  top: -40px;
  right: -40px;
  background: #eb0a1e;
  width: 190px;
  height: 190px;
  padding: 10px !important;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: scale(0.8);
}

.descuento * {
  padding: 0 !important;
  text-align: center;
  color: #fff;
}

.descuento h3 {
  font-size: 50px !important;
  line-height: 100%;
}

.descuento h4 {
  font-size: 23px;
  line-height: 80%;
}

.descuento p {
  font-size: 18px;
}

.carCards {
  display: flex;
  flex-direction: column;
  width: 46%;
  margin-bottom: 80px;
}

.carCards img {
  width: 110%;
  align-self: center;
  max-width: 520px;
  position: absolute !important;
  bottom: -230px;
  pointer-events: none;
}

.containCard span {
  font-weight: 900;
}

.containCard {
  display: flex;
  flex-direction: column;
  padding: 40px 20px 80px;
  position: relative;
  min-height: 320px;
}

.containCard::before {
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;
  left: 0;
  bottom: 0;
  background: #f0eeee;
  box-shadow: 10px 20px 42px 0 rgb(55 55 55 / 23%);
  border-radius: 5px;
}

.containCard * {
  position: relative;
}

.containCard *:not(img, button, a) {
  padding-left: 50px;
}

.carCards h3 {
  font-family: "Toyota Type";
  font-weight: 900;
  font-size: 30px;
}

.carCards h2 {
  font-weight: 700;
  color: #191919;
  width: 80%;
}

.carCards p {
  color: rgb(0 0 0 / 55%);
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

.carCards .resal {
  color: #191919;
}

.cuota span {
  padding: 0 !important;
  font-size: 35px;
  margin-top: -16px;
  font-weight: 700;
}

.cuota {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  color: #eb0a1e;
}

.carCards .btn {
  margin-top: 170px;
  width: fit-content;
  align-self: center;
}

.hiluxCard img,
.corollaCrossCard img {
  max-width: 580px;
  bottom: -270px;
}

.imgRed {
  width: 100% !important;
  margin-top: -10px !important;
}

.imgAmp {
  margin-top: -50px !important;
  width: 130% !important;
}

.hibrido {
  padding: 2px 12px 4px !important;
  color: #fff;
  font-size: 15px;
  background: #276699;
  vertical-align: super;
  margin-left: 10px;
}
.buttonDiv {
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: center;
  margin-right: 50px;
  gap: 20px;
}
/* /Models */

@media (max-width: 850px) {
  /* Models */
  .descuento {
    transform: scale(0.7);
    top: -72px;
    right: -55px;
  }

  .descuento p {
    font-size: 18px !important;
  }

  .carCards {
    width: inherit;
    margin-bottom: 50px;
    width: 100%;
  }

  .containCard {
    padding: 60px 20px 60px;
    margin-bottom: 20px;
  }

  .carCards:last-child {
    margin-bottom: 20px;
  }

  .containCard *:not(img) {
    padding-left: 20px;
  }

  .containCard::before {
    left: 0px;
    box-shadow: 5px 8px 42px 0 rgb(55 55 55 / 33%) !important;
  }

  .imgRed {
    width: 110% !important;
    margin-top: 0px !important;
  }

  .imgAmp {
    width: 140% !important;
    margin-top: -20px !important;
  }

  .carCards p {
    font-size: 15px;
  }

  .carCards img {
    width: 120%;
    max-width: 400px;
    bottom: -160px;
  }

  .carCards .boton {
    margin-left: -10px;
  }

  .carCards .btn {
    margin-top: 80px;
  }
  .buttonDiv {
    width: 100%;
    margin-right: 0px;
  }

  /* /Models */
}
