.galeriaText {
  margin-top: 40px;
  border-radius: 10px;
  background: #eb0a1e17;
}

.galeria {
  width: 48%;
}

.galeriaSwiper {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.galeriaSwiper .swiper-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.galeriaSwiper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  background: #fff !important;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background: #eb0a1e !important;
}

.textModel {
  width: 48%;
  padding: 30px 0;
}
.textModel .cuota {
  margin-bottom: 10px;
}
.textModel .cuota span {
  font-size: 47px;
}
.textModel p {
  font-size: 16px;
  margin: 0 0 10px;
}
.textModel h2 {
  margin-bottom: 10px;
}
.textModel .btn {
  margin-top: 20px;
  width: fit-content;
}

/* Media */

@media (max-width: 850px) {
  .galeria,
  .textModel {
    width: 100%;
  }
  .textModel {
    margin: 0 20px;
    padding: 30px 10px;
  }
  .galeriaSwiper {
    height: 300px;
    border-radius: 0 0 10px 10px;
  }
  .galeriaText {
    flex-direction: column-reverse;
  }
}
